
import {
    apiSetMealLists,
    apiSetMealSwitchStatus,
    apiSetMealDelete,
} from "@/api/shop";
import {Component, Prop, Vue} from "vue-property-decorator";
import lsPagination from "@/components/ls-pagination.vue";
import ExportData from "@/components/export-data/index.vue";
import LsDialog from "@/components/ls-dialog.vue";
import {RequestPaging} from "@/utils/util";
import DatePicker from "@/components/date-picker.vue";

@Component({
    components: {
        lsPagination,
        ExportData,
        LsDialog,
        DatePicker,
    },
})
export default class SetMealList extends Vue {
    /** S Data **/

    apiSetMealLists = apiSetMealLists;

    pager: RequestPaging = new RequestPaging();

    searchObj: Object = {
        status: "",
        shop_info: "",
        start_time: "",
        end_time: "",
    };

    /** E Data **/

    /** S Methods **/

    // 获取商城列表
    getSetMealLists(): void {
        this.pager.request({
            callback: apiSetMealLists,
            params: {
                ...this.searchObj,
            },
        });
    }

    // 修改商城状态
    async shopSwitchStatusEdit(status: number, id: number): Promise<void> {
        await apiSetMealSwitchStatus({id, status: status});
        this.getSetMealLists();
    }

    // 删除商城
    async onSetMealDelete(row: any): Promise<void> {
        await apiSetMealDelete({id: row.id});
        this.getSetMealLists();
    }

    // 重置搜索
    reset(): void {
        Object.keys(this.searchObj).map((key) => {
            this.$set(this.searchObj, key, "");
        });
        this.getSetMealLists();
    }

    // 去编辑商城
    toSetMealEdit(id: number | any): void {
        this.$router.push({
            path: "/shop/set_meal_edit",
            query: {
                id: id,
            },
        });
    }

    /** E Methods **/

    /** S ods **/
    created() {
        this.getSetMealLists();
    }
}
